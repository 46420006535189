import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PROFILE } from '../../assets';

/*

FORM CODE FOR LOGIN
CURRENTLY NOT AUTHENTICATING ANYTHING ON THE BACK END!!!

- ZB

*/

interface props {
    className?: string
}

const LoginFormComp: FC<props> = ({ className }) => {

    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [auth, setAuth] = useState(false);

    const handleLogin = () => {
        console.log(name, password);
        password !== '' ? setAuth(true) : setAuth(false);
    }

    const updateName = (e: any) => {
        setName(e.target.value)
        console.log(name)
    }

    const updatePassword = (e: any) => {
        setPassword(e.target.value)
        console.log(password)
    }

    return (
        <>
        <section className={className}>
            <section className="login-window">
                <div className="list">

                    {/* USER PROFILE PHOTO */}
                    <img alt="User Avatar" className='icon' src={PROFILE} />

                    <div className="text-container">
                        <input className="text-field" id='name' placeholder='username' value={name} onChange={(e) => updateName(e)} />
                        <input className="text-field" type="password" id='pw' placeholder='password' value={password} onChange={(e) => updatePassword(e)} />
                    </div>

                    <div className="button-container">
                        
                            {
                            !auth 
                            ?
                            <Link to="/nav">
                            <button onSubmit={handleLogin} onClick={handleLogin}>login</button>
                            </Link>
                            : 
                            <Link to="/nav">
                            <button onSubmit={handleLogin} onClick={handleLogin}>login</button>
                            </Link>
                            }
                        
                    </div>
                </div>
            </section>
        </section>
        </>
    );
};

const LoginForm = styled(LoginFormComp)`

    
.login-window {
    margin: 0 auto;
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    border-radius: 10px;
    min-height: 30em;
    min-width: 30em;
    z-index: 10;
    display: flex;
    justify-content: center;

    & h1 {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: white;
    }

    & input {
        margin-top: 1em;
        min-width: 20em;
        border-radius: 10px;
        border: none;
        opacity: 40%;
        background-color: antiquewhite;

    }

    & button {
        border-radius: 10px;
        border: none;
        background-color: darkgray;
        color: whitesmoke;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 5em;
        width: 10em;
        height: 3em;
        margin-left: 5px;
        margin-right: 5px;
    }

    & button:hover {
        background-color: whitesmoke;
        color: black;
    }

    & input::placeholder {
            font-style: italic;
            text-align: center;
    }

    & input::content {
            font-style: italic;
            text-align: center;
    }

    & input:focus {
        opacity: 100%;
    }

    & .icon {
        margin: auto;
        background-color: white;
        max-width: 8em;
        min-height: 8em;
        max-height: 10em;
        opacity: 90%;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        border: 2px antiquewhite solid;
        

    }
}

.text-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.list {
    margin-top: 2em;
}

.button-container {
    display: flex;
    justify-content: center;
}

`

export default LoginForm;