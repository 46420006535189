import React, { FC } from 'react';
import styled from 'styled-components';

import { MainShell } from '../../components/Shell/SharedShell';
import { LoginForm } from '../../../resources';

/*

LOGIN PAGE

- ZB

*/


interface props {
    className?: string,
}

const LoginComp: FC<props> = ({ className }) => {
    return (
            <MainShell className={className}>
                <div className="body">
                    <LoginForm />
                </div>
            </MainShell>
    );
}

const Login = styled(LoginComp)`
    
    .body {
    background: rgb(2,0,36);
    background: linear-gradient(170deg, rgba(2,0,36,1) 0%, rgba(14,14,66,1) 35%, rgba(80,43,55,1) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    align-items: center;
}

`

export default Login;