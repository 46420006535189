import React, { FC } from 'react';
import styled from 'styled-components';

// COMPONENT IMPORTS
import { AppList, Card } from '../../components';

// LIB AND ASSET IMPORTS
import { App } from '../../../lib/app'

import { QBPNG, QPPNG, QRLPNG } from '../../../assets';
import { FaRobot } from 'react-icons/fa';
import { MainShell } from '../../components/Shell/SharedShell';

/*

MASTER APP NAVIGATION PAGE
UI FOR MAIN APP ROUTER DIRECTING TO SUB APPS

- ZB

*/

interface props {
    className?: string
}

const appArr: App[] = [
    {
        name: "Quick Response Library",
        flavor: "all your documents -- at your fingertips.",
        icon: QRLPNG,
        link: "/qrl/home"
    },
    {
        name: "Quick Parts",
        flavor: "make connections -- easy",
        icon: QPPNG,
        link: "/qp/home"
    },
    {
        name: "Quick Builder",
        flavor: "procedure creation -- done simple.",
        icon: QBPNG, 
        link: "/qb/home"
    },
    {
        name: "Quick Robot",
        flavor: "find what best fits your needs",
        element: <FaRobot size={70} />,
        link: "/decider"
    }
]

const NavigationPageComp:FC <props> = ({ className }) => {
    return (
        <>
            <MainShell className={className}>
                <div className="parent">
                    <AppList apps={appArr}  />
                    <Card />
                </div>
            </MainShell>
        </>
    );
};

const NavigationPage = styled(NavigationPageComp)`

    .parent {
        background: rgb(2,0,36);
        background: linear-gradient(170deg, rgba(2,0,36,1) 0%, rgba(14,14,66,1) 35%, rgba(80,43,55,1) 100%);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: top;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        min-height: 100%;
        align-items: center;
    }
`

export default NavigationPage;