import { EPost, User, CommentElement, PostElement } from "./app";
import { EXVIDEO, TRAINING } from "../assets"
import { EN } from "./translations";

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const UserArr: User[] = [
    {
        id: randomIntFromInterval(1, 100000),
        username: "Bon Jovi",
        company: "ITS",
        accountLevel: "USER",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Journey",
        company: "ITS",
        accountLevel: "USER",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Madonna",
        company: "ITS",
        accountLevel: "EDITOR",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "John Lennon",
        company: "ITS",
        accountLevel: "USER",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Ian Mckay",
        company: "ITS",
        accountLevel: "USER",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Trevor Tress",
        company: "ITS",
        accountLevel: "ADMIN",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Bruce Rick",
        company: "ITS",
        accountLevel: "ADMIN",
        language: EN
    },
    {
        id: randomIntFromInterval(1, 100000),
        username: "Zachary Burcar",
        company: "ITS",
        accountLevel: "ADMIN",
        language: EN
    },
]

let currentTime = new Date();

const min = 0;
const max = UserArr.length;

export const CommentArr: CommentElement[] = [
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Must go faster... go, go, go, go, go! They're using our own satellites against us. And the clock is ticking. Must go faster... go, go, go, go, go! Checkmate... Did he just throw my cat out of the window? You know what? It is beets. I've crashed into a beet truck.",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "on your dinosaur tour, right? Did he just throw my cat out of the window? You're a very talented young man, with your own clever thoughts and ideas. Do you need a manager?",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Must go faster... go, go, go, go, go! They're using our own satellites against us. And the clock is ticking. Must go faster... go, go, go, go, go! Checkmate",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Must go faster... go, go, go, go, go! They're using our own satellites against us. And the clock is ticking. Must go faster... go, go, go, go, go! Checkmate",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: " God help us, we're in the hands of engineers. Must go faster... go, go, go, go, go!",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Yeah, but your scientists were so preoccupied with whether or not they could, they didn't stop to think if they should. You know what? It is beets. I've crashed into a beet truck.",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Life finds a way. God help us, we're in the hands of engineers. What do they got in there? King Kong?",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "You're a very talented young man, with your own clever thoughts and ideas.",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Do you need a manager?",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Remind me to thank John for a lovely weekend.",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "So you two dig up, dig up dinosaurs? We gotta burn the rain forest, dump toxic waste, pollute the air,",
        dateCreated: currentTime
    },
    {
        id: randomIntFromInterval(1, 100000),
        user: UserArr[randomIntFromInterval(min, max)],
        body: "Cause maybe if we screw up this planet enough, they won't want it anymore!",
        dateCreated: currentTime
    },
]

export const postArr: PostElement[] = [
    {
        title: "Annotations Demo",
        body: "Check out these annotations",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/23/2023",
        postType: EPost.Annotations,
        link: "#",
    },
    {
        title: "New 3D Render of Rebel Cobot",
        body: "You're obsessed with the fat lady! Drive us out of here! Did he just throw my cat out of the window? You're a very talented young man, with your own clever thoughts and ideas.",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/8/2023",
        postType: EPost.Model,
        link: "#",
    },
    {
        title: "New Video added to Training Library",
        body: "You know what? It is beets. I've crashed into a beet truck. We gotta burn the rain forest, dump toxic waste, pollute the air, and rip up the OZONE!",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/4/2023",
        postType: EPost.Video,
        link: "#",
        src: `${EXVIDEO}`
    },
    {
        title: "Files need to be updated...",
        body: "",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/8/2023",
        postType: EPost.Update,
        link: "#",
    },
    {
        title: "Remind me to thank John for a lovely weekend.",
        body: "Do you have any idea how long it takes those cups to decompose. My dad once told me, laugh and the world laughs with you, Cry, and I'll give you something to cry about you little jerk.",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/5/2023",
        postType: EPost.Document,
        link: "#",
    },
    {
        title: "Jaguar shark! So tell me - does it really exist?",
        body: "You're a very talented young man, with your own clever thoughts and ideas. Do you need a manager? Hey, take a look at the earthlings. Goodbye! Do you have any idea how long it takes those cups to decompose.",
        dateCreated: "8/2/2023",
        comments: [],
        postType: EPost.Update,
        link: "#",
    },
    {
        title: "This thing comes fully loaded.",
        body: "Yeah, but John, if The Pirates of the Caribbean breaks down, the pirates don’t eat the tourists. Must go faster... go, go, go, go, go!",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/1/2023",
        postType: EPost.Document,
        link: "#",
    },
    {
        title: "They're using our own satellites against us.",
        body: " King Kong? We gotta burn the rain forest, dump toxic waste, pollute the air, and rip up the OZONE! 'Cause maybe if we screw up this planet enough, they won't want it anymore!",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "8/4/2023",
        postType: EPost.Image,
        link: "#",
        src: `${TRAINING}`
    },
    {
        title: "AM/FM radio, reclining bucket seats, and... power windows.",
        body: "God creates dinosaurs. God destroys dinosaurs. God creates Man. Man destroys God. Man creates Dinosaurs. Jaguar shark! So tell me - does it really exist?",
        comments: [
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
            CommentArr[randomIntFromInterval(0, CommentArr.length)],
        ],
        dateCreated: "7/29/2023",
        postType: EPost.Update,
        link: "#",
    },
]