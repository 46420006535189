import { ReactNode } from "react";
import { Lang } from "../translations";

export enum EPost {
    Update,
    Image,
    Document,
    Video,
    Model,
    Annotations,
}

export type App = {
    name: string,
    flavor?: string,
    icon?: string,
    element?: ReactNode,
    link: string,
};

export type PostElement = {
    title: string,
    body?: string,
    comments: CommentElement[],
    dateCreated: string,
    postType: EPost,
    link?: string,
    src?: string
}

export type CommentElement = {
    id: number,
    user: User,
    body: string,
    dateCreated: Date | string
}

export type User = {
    id: number,
    username: string,
    company: string,
    accountLevel: "USER" | "ADMIN" | "EDITOR",
    savedPosts?: PostElement[],
    comments?: CommentElement[],
    language?: Lang
}

