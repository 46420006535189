import { forwardRef, PropsWithChildren, Ref, FC } from 'react'
import styled from 'styled-components'


interface props {
    className?: string,
    icon?: string,
    text?: string
}

export const TitleCard = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
);

export const GreetingWindow = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
            {...props}
            ref={ref}
            className={className}
        />
    )
);

const WelcomeComp:FC <props> = ({ className, text }) => {
    return (
        <GreetingWindow className={className}>
            <h1>{text}</h1>
        </GreetingWindow>
    )
}

export const Welcome = styled(WelcomeComp)`

margin: 0 auto;
    
h1 {
    color: antiquewhite;
    font-weight: 700;
    font-style: italic;
    text-align: center;
}

`

const BannerComp:FC <props> = ({ className, icon }) => {
    return (
        <TitleCard className={className}>
            <img alt="Quick Suite Logo" src={icon} />
            <h1>QUIK <span>SUITE</span> </h1>
        </TitleCard>
    )
};

export const Banner = styled(BannerComp)`

display: flex;
flex-direction: row;
align-items: center;

img {
    width: 5em;
    height: 5em;
}

h1 {
    font-weight: 700;
    color: antiquewhite;
    font-style: italic;
}

span {
    font-size: large;
}

`

