import React, { FC } from 'react';
import styled from 'styled-components';
import { App } from '../../../lib/app';
import { Banner } from './Tools';
import { QSPNG } from '../../../assets';
/*

COMPONENT THAT GENERATES LIST OF APPS IN THE LANDING PAGE DASHBOARD

- ZB

*/

interface props {
    className?: string
    apps: App[]
}

const AppListComp:FC <props> = ({ className, apps }) => {
    return (
        <div className={className}>
            <Banner icon={QSPNG}/>
            <div className="container">
                {apps.map(el => {
                    return (
                    <div className="element">
                        <a href={el.link}>
                        {el.icon ? 
                        <img alt={`${el.name} logo`} className="icon" src={el.icon ?? el.icon} />
                        : null
                        }
                        {el.element && el.element}
                        <h2>{el.name}</h2>                     
                        </a>
                    </div>
                    );
                })}
            </div>
        </div>
    )
};

export const AppList = styled(AppListComp)`


/* margin-left: 10em; */

.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    border: antiquewhite 2px solid;
    border-radius: 10px;
}
    
.element {
    
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: auto;
    margin-top: .3em;
    margin-bottom: .3em;
    border-radius: 10px;
    width: 40em;
    height: 8em;
    background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
    backdrop-filter: blur(10px); 
    box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
    padding: 1em;

    & img {
        width: 5em;
        height: 5em;
    }

    & svg {
        width: 5em;
        height: 5em;
        text-decoration: none;
        color: salmon;
    }
    
    & a {
        text-decoration: none;
        display: flex;
    }

    & h2 {
        text-align: left;
        margin: auto;
        margin-left: 1em;
        font-weight: 700;
        text-decoration: none;
        color: antiquewhite;
    }
}

.element:hover {
    transform: scale(1.01);
}
    

`