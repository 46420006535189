import React from 'react';

// routers
import GlobalRouter from './GlobalRouter';
import QBRouter from './QBRouter';
import QPRouter from './QPRouter';
import QRLRouter from './QRLRouter';

const Router = () => {
	return (
		<>
			<GlobalRouter />
			<QBRouter />
			<QPRouter />
			<QRLRouter />
		</>
	);
};

export default Router;