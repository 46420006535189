import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Welcome } from './Tools';

import { PROFILE } from '../../../assets'

/*

USER / COMPANY CARD FOR LANDING PAGE

- ZB

*/

interface props {
    className?: string
}

type User = {
    name: string,
    icon: string,
    company: string,
    accountLevel: string,
    notifications?: ReactNode
}

const user: User = {
    name: "Zachary",
    icon: PROFILE,
    company: "ITS",
    accountLevel: "Admin",
}

const CardComp:FC <props> = ({ className }) => {
    return (
        <section className={className}>
            <Welcome text={`Welcome, ${user.name}`}/>
            <div className="container">
                <img alt="User Avatar" src={user.icon} />
                
                <div className="account-info">
                    <h2>Company: {user.company}</h2>
                    <h2>Account Level: {user.accountLevel}</h2>
                </div>

                <button>Edit Account</button>

                <select placeholder="Notifications">
                        <option>Notifications</option>
                        <option>QuickParts Update: v.07</option>
                        <option>New Documents Added to QRL</option>
                        <option>New Documents Created from QuickBuilder</option>
                        {/* {user.notificaions && user.notifications.map((n: string, index: number) => {
                            return <option key={index} onChange={(e) => handleClick(n)}>{n}</option>
                        })} */}
                </select>
            </div>
        </section>
    );
};

export const Card = styled(CardComp)`

display: flex;
flex-direction: column;

.container {
    border: 1px antiquewhite solid ;
    width: 25em;
    height: 30em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
        width: 10em;
        height: 10em;
        border-radius: 100%;
        border: 2px dashed white;
        padding: 5px;
        margin-bottom: 2em;
    }

    & h2 {
        color: antiquewhite
    }

    & .account-info {
        background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
        backdrop-filter: blur(10px); 
        box-shadow: 10px 10px 10px rgba(30,30,30,0.2);
        border-radius: 10px;
        padding: 10px;
    }

    select {
        list-style: none;
        text-decoration: none;
        width: 18em;
        height: 3em;
        text-align: center;
        font-weight: 700;
        cursor: pointer;
        box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
        background-color: #fff;
        font-size: 1em;
        color: #36454f;
        border: none;
        border-radius: 10px;
        margin-top: 1em;
    }

    button {
        list-style: none;
        text-decoration: none;
        width: 18em;
        height: 3em;
        text-align: center;
        font-weight: 700;
        cursor: pointer;
        box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);
        background-color: #9e005d;
        font-size: 1em;
        color: #fff;
        border: none;
        border-radius: 10px;
        margin-top: 1em;
    }
}

`