import { forwardRef, PropsWithChildren, Ref, FC, ReactNode, useRef, ReactElement } from 'react'
import styled from 'styled-components'
import { FaGraduationCap, FaBookOpen, FaPhotoVideo, FaHome } from 'react-icons/fa'
import { HiDocument } from 'react-icons/hi';
import { TbWorldWww } from 'react-icons/tb';
import { GiCube } from 'react-icons/gi';

/*


THIS FILE HOLDS DIFFERENT TOOLS FOR THE QRL UI AND FUNCTIONALITY
CURRENTLY THE SERVICES LIST IS SUPPOSED TO RENDER THE CORRECT ELEMENT PULL OUT WHEN HOVERED
IT DOES NOT

SORRY!

-ZB


*/

interface props {
    className?: string
    children?: string | ReactNode | ReactElement
    el?: NavItem
    text?: string
    onMouseEnter?: (e: any) => void
    onMouseLeave?: (e: any) => void
}


type NavItem = {
    name: string
    image: ReactNode
    link: string
}

const arr: NavItem[] = [
    {
        name: "Home",
        image: <FaHome size={50} />,
        link: "/qrl/home"
    },
    {
        name: "Documents",
        image: <HiDocument size={50} />,
        link: "/qrl/documentation"
    },
    {
        name: "Training",
        image: <FaGraduationCap size={50} />,
        link: "/qrl/training"

    },
    {
        name: "Media",
        image: <FaPhotoVideo size={50} />,
        link: "/qrl/media"

    },
    {
        name: "3D Models",
        image: <GiCube size={50} />,
        link: "/qrl/models"
    },
    {
        name: "Procedures",
        image: <FaBookOpen size={50} />,
        link: "/qrl/procedures"
    },
    {
        name: "External Content",
        image: <TbWorldWww size={50} />,
        link: "/qrl/external-content"
    },
]

// #region MENU

const List = forwardRef(
    (
        { className, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        />
    )
);

const Service = forwardRef(
    (
        { className, children, el, onMouseEnter, onMouseLeave, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        
        <div
        {...props}
        ref={ref}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        >
        <a href={el && el.link}>
        {el && el.image}
        <p>{el && el.name}</p>
        {children}
        </a>
        </div>
    )
)

const ActiveTab = forwardRef(
    (
        { className, children, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        >
        {children}
        </div>
    )
)

export const Folder = forwardRef(
    (
        { className, children, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        >
        {children}
        </div>
    )
)

export const MediaElement = forwardRef(
    (
        { className, children, ...props }: PropsWithChildren<props>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
        {...props}
        ref={ref}
        className={className}
        >
        {children}
        </div>
    )
)

const ServicesComp:FC <props> = ({ className }) => {
    const sRef = useRef(null);
    // const [name, setName] = useState<string>('');
    const name: string = '';

    const handleMouseEnter = (el: string) => {
        // if (el !== el) { setName(el) }
            
    }

    const handleMouseExit = (el: string) => {
        // if (el !== el) { setName(el) }
            
    }

    const handleTab = () => {
        return (
            <ActiveTab
            onMouseEnter={(e: any) => handleMouseEnter(name)}
            onMouseLeave={(e: any) => handleMouseExit(name)}
            >
                <div className={`${'unselected'}`}>
                    {name &&
                        <div>
                            <h1>{name}</h1>
                        </div>
                    }
                </div>
            </ActiveTab>
        );
    }

    return (
        <List className={className}>
            {arr.map((el, i) => {
                return (
                    <Service key={i} el={el}
                    ref={sRef}
                    onMouseEnter={(e: any) => handleMouseEnter(el.name)}
                    onMouseLeave={(e: any) => handleMouseExit(el.name)}
                    >
                            {handleTab()}
                    </Service>
                );
            })}
            
        </List>
    )
};

export const Services = styled(ServicesComp)`

    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #713458;
    border-radius: 0  10px 10px 0;
    /* border-right: 2px dashed antiquewhite; */
    border-top: 2px dashed antiquewhite;
    border-bottom: 2px dashed antiquewhite;
    margin-top: 1em;
	height: calc(100vh - var(--header-height) - var(--header-height)); // (page - header - footer)
	top: var(--header-height); // shift past header
    width: var(--navbar-width);


    div {
        margin-left: 1em;
        border-radius: 10px;
    }

    div:hover {
        transform: scale(1.05);
    }

   svg {
        max-width: 50px;
        min-width: 20px;
        margin-top: 1em;
        margin-bottom: 1em;
        position: top;
        color: antiquewhite;
        padding-right: 1em;
        cursor: pointer;
        
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: antiquewhite;
        font-weight: 500;

        & p {
            margin-top: 1em;

        }
    }

    @media screen and (max-width: 1024px) {
        width: var(--navbar-width-mobile);

        div {
            margin-left: .4em;
            margin-right: 10px;
        }

        a {
            display: flex;
            align-items: center;
            color: antiquewhite;


            & svg {
                min-width: 50px;
                color: antiquewhite;
                margin-top: 0;
                margin-bottom: 0;
            }

        }

    }

    @media screen and (max-width: 768px) {
        position: fixed;
        flex-direction: row;
        width: 100vw;
        height: var(--navbar-height-mobile);
        margin-top: .2em;
        border-radius: 0;
        z-index: 100;


        & div {
            margin: auto;
            display: flex;
        }

        a {
            display: flex;
            align-items: center;
            color: antiquewhite;

            & svg {
                min-width: 20px;
                color: antiquewhite;
                margin-top: 0;
                margin-bottom: 0;
            }

            p {
                display: none;
            }
        }

    }

    @media screen and (max-width: 480px) {

        flex-direction: row;
        width: 100vw;
        height: var(--navbar-height-mobile);
        margin-top: .2em;
        border-radius: 0;

        & div {
            margin: auto;
            display: flex;
        }

        a {
            display: flex;
            align-items: center;
            color: antiquewhite;

            & svg {
                min-width: 20px;
                color: antiquewhite;
                margin-top: 0;
                margin-bottom: 0;
            }

            p {
                display: none;
            }
        }






        
    }

    /* .selected {
        width: 20em;
        margin-left: 6em;
        color: antiquewhite;
        background-color: #713458;
        height: 70vh;
        position: absolute;
        top: 0;
    } */

    /* .unselected {
        display: none;
        width: 20em;
        height: 100%;
        margin-left: 6em;
        color: antiquewhite;
        background-color: #713458;
        height: 70vh;
        position: fixed;
        top: 0;
        margin-top: 8vh;
    } */
`