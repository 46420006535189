import { FC, useState } from 'react';
import styled from 'styled-components';
// import '@google/model-viewer';

// const model = require('../../resources/robot.glb');
import { fanuc }  from '../';

// interface PositionArray {
//     x: number;
//     y: number;
//     z: number;
// }


const AnnotationsComp:FC <IGlobalProps> = ({ className }) => {
    const [x, setX] = useState<number>(0);
    // const modelViewerRef = useRef<HTMLDivElement | null>(null);
  
    // const handleSpotClick = (e: any) => {
    //     const target = e.target.getAttribute('data-position');
    //     console.log(target);
    //     const event = new CustomEvent('spotClicked', {
    //       detail: { position: target },
    //     });
    
    //     if (modelViewerRef.current) {
    //       modelViewerRef.current.dispatchEvent(event);
    //       console.log(event);
    //     }
    // };

    const handleX = () => {
        let cur = x;
        setX(cur += 0.1)
        console.log(x);
    }
  
    return (
        <div className={className}>
        <div className="edit">
            <button onClick={handleX}>Update X</button>
        </div>
        <model-viewer src={fanuc} className={className} ar-modes="webxr scene-viewer quick-look" camera-controls shadow-intensity="1" style={{ width: '100vw', height: '100vh' }}>
            <button  className="hotspot" slot="hotspot-1" data-position={`${x.toString()}m 0.5m 0.0m`} data-normal="0m 0m 1m" data-visibility-attribute="visible">
                <div className="annotation">
                    Test Annotation
                </div>
            </button>
        </model-viewer>
        </div>
    );
  };

const Annotations = styled(AnnotationsComp)`

display: flex;

.edit {
    width: 20em;
    height: 100vh;
    background-color: salmon;
}

.hotspot{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
    box-sizing: border-box;
    pointer-events: none;
  }

.annotation {
    background-color: #888888;
    position: absolute;
    transform: translate(10px, 10px);
    border-radius: 10px;
    padding: 10px;
}
`

export default Annotations;